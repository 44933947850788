/***引入axios***/
import axios from 'axios'
import address from '@/assets/js/area'
import drugType from '@/assets/js/drugType'
import { Message } from 'element-ui'

//正式地址
const pathUrl = 'https://yluser.hzty-pension.cn/lifeServe'
//登录页地址
const loginUrl = 'https://yluser.hzty-pension.cn/lifeServe'
let base = {
  /***get请求***/
  getAjax(url, dataObj) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: pathUrl + url,
        params: dataObj,
        headers: {
          Authorization: sessionStorage.getItem('Authorization'),
        },
        timeout: 60000,
      })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.code == 200) {
              resolve(res)
            } else if (res.data.code == 205) {
              Message.error(res.data.msg)
              if (url == '/login') {
                resolve(res)
              } else {
                location.href = loginUrl
              }
              return
            } else {
              Message.error(res.data.msg)
              resolve(res)
            }
          } else {
            Message.error(res.statusText)
            return
          }
        })
        .catch(function (error) {
          if (error.response) {
            Message.error('系统故障，请联系管理员')
            return
          } else if (error.request) {
            Message.error('请求超时，请重新登录')
            return
          } else {
            Message.error(error.message)
            return
          }
        })
    })
  },
  /***post请求
   * JSON***/
  postAjax(url, data, newValue) {
    let onlineUrl = pathUrl + url
    if (newValue) {
      onlineUrl = pathUrl + url + newValue
    }
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: onlineUrl,
        headers: {
          Authorization: sessionStorage.getItem('Authorization'),
        },
        data: data,
        timeout: 60000,
      })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.code == 200) {
              resolve(res)
            } else if (res.data.code == 205) {
              Message.error(res.data.msg)
              if (url == '/login') {
                resolve(res)
              } else {
                location.href = loginUrl
              }
              return
            } else if (res.data.code == 3406) {
              resolve(res)
              return
            } else if (res.data.code == 505) {
              resolve(res)
              Message.warning(res.data.msg)
              return
            } else {
              Message.error(res.data.msg)
              resolve(res)
            }
          } else {
            Message.error(res.statusText)
            return
          }
        })
        .catch(function (error) {
          if (error.response) {
            Message.error('系统故障，请联系管理员')
            return
          } else if (error.request) {
            Message.error('请求超时，请重新登录')
            return
          } else {
            Message.error(error.message)
            return
          }
        })
    })
  },
  /***post请求
   * FORM-DATA***/
  postForm(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: pathUrl + url,
        transformRequest: [
          function (data) {
            let ret = ''
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                '=' +
                encodeURIComponent(data[it]) +
                '&'
            }
            return ret
          },
        ],
        headers: {
          Authorization: sessionStorage.getItem('Authorization'),
        },
        data: data,
        timeout: 60000,
      })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.code == 200) {
              resolve(res)
            } else if (res.data.code == 205) {
              Message.error(res.data.msg)
              if (url == '/login') {
                resolve(res)
              } else {
                location.href = loginUrl
              }
              return
            } else {
              Message.error(res.data.msg)
              resolve(res)
            }
          } else {
            Message.error(res.statusText)
            return
          }
        })
        .catch(function (error) {
          Message.error(error.message)
          reject()
        })
    })
  },
  /***delete请求 */
  deleteAjax(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'delete',
        url: pathUrl + url,
        data: data,
        headers: {
          Authorization: sessionStorage.getItem('Authorization'),
        },
        timeout: 60000,
      })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.code == 200) {
              resolve(res)
            } else if (res.data.code == 205) {
              Message.error(res.data.msg)
              if (url == '/login') {
                resolve(res)
              } else {
                location.href = loginUrl
              }
              return
            } else {
              Message.error(res.data.msg)
              resolve(res)
            }
          } else {
            Message.error(res.statusText)
            return
          }
        })
        .catch(function (error) {
          if (error.response) {
            Message.error('系统故障，请联系管理员')
            return
          } else if (error.request) {
            Message.error('请求超时，请重新登录')
            return
          } else {
            Message.error(error.message)
            return
          }
        })
    })
  },
  deleteRequest(url) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'delete',
        url: pathUrl + url,
        headers: {
          Authorization: sessionStorage.getItem('Authorization'),
        },
        timeout: 60000,
      })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.code == 200) {
              resolve(res)
            } else if (res.data.code == 205) {
              Message.error(res.data.msg)
              if (url == '/login') {
                resolve(res)
              } else {
                location.href = loginUrl
              }
              return
            } else {
              Message.error(res.data.msg)
              resolve(res)
            }
          } else {
            Message.error(res.statusText)
            return
          }
        })
        .catch(function (error) {
          if (error.response) {
            Message.error('系统故障，请联系管理员')
            return
          } else if (error.request) {
            Message.error('请求超时，请重新登录')
            return
          } else {
            Message.error(error.message)
            return
          }
        })
    })
  },
  /*获取uuid*/
  guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }
    )
  },
  /***数字验证***/
  isNumberVerify(val) {
    var myReg = /^[0-9]+(\.\d+)?$/
    if (!myReg.test(val)) {
      return false
    } else {
      return true
    }
  },
  /***数字格式化***/
  numberFormatting(val) {
    if (this.isNumberVerify(val)) {
      return parseFloat(val)
    } else {
      return 0
    }
  },
  /***姓名验证***/
  isNameVerify(name) {
    var myReg =
      /^(?:[\u4e00-\u9fa5]+)(?:●[\u4e00-\u9fa5]+)*$|^[a-zA-Z0-9]+\s?[\.·\-()a-zA-Z]*[a-zA-Z]+$/
    if (!myReg.test(name)) {
      return false
    } else {
      return true
    }
  },

  /***手机号验证***/
  isPoneAvailable(val) {
    var myreg =
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    if (myreg.test(val)) {
      return true
    } else {
      return false
    }
  },
  /**身份证 */
  isCardVerify(card) {
    var myReg =
      /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
    if (!myReg.test(card)) {
      return false
    } else {
      return true
    }
  },
  /**获取省市 */
  getAddress() {
    let cityArr = []
    let provinceArr = []
    for (let i = 11; i < 83; i++) {
      let children = []
      let children2 = []
      for (const key in address.city_list) {
        let obj = {}
        if (key.toString().substring(0, 2) < i + 1) {
          if (key.toString().substring(0, 2) == i) {
            obj.label = address.city_list[key]
            obj.value = address.city_list[key]
          }
          if (obj.label) {
            children.push(obj)
          }
        }
      }
      for (const key in address.province_list) {
        let obj = {}
        if (key.toString().substring(0, 2) < i + 1) {
          if (key.toString().substring(0, 2) == i) {
            obj.label = address.province_list[key]
            obj.value = address.province_list[key]
          }
          if (obj.label) {
            children2.push(obj)
          }
        }
      }
      if (children.length > 0) {
        cityArr.push(children)
      }
      if (children2.length > 0) {
        provinceArr.push(children2)
      }
    }
    let cityChildren = [...new Set(cityArr)]
    let provinceChildren = [...new Set(provinceArr)]
    for (let j = 0; j < provinceChildren.length; j++) {
      provinceChildren[j][0].children = cityChildren[j]
    }
    let newArr = []
    for (let i = 0; i < provinceChildren.length; i++) {
      let obj = provinceChildren[i][0]
      newArr.push(obj)
    }
    return newArr
  },
  /*获取省市区*/
  getAllAddress() {
    let cityArr = []
    let provinceArr = []
    let countyArr = []
    for (let i = 11; i < 83; i++) {
      let children = []
      let children2 = []
      let children3 = []
      for (const city in address.city_list) {
        if (city.toString().substring(0, 2) < i + 1) {
          if (city.toString().substring(0, 2) == i) {
            let newArr = []
            for (const key in address.county_list) {
              let obj = {}
              if (
                key.toString().substring(0, 2) == i &&
                parseInt(key) >= parseInt(city) &&
                parseInt(key) < this.accAdd(city, 100)
              ) {
                obj.label = address.county_list[key]
                obj.value = address.county_list[key]
                obj.areaCode = key
                newArr.push(obj)
              }
            }
            if (newArr.length > 0) {
              children3.push(newArr)
            }
          }
        }
      }

      for (const key in address.city_list) {
        let obj = {}
        if (key.toString().substring(0, 2) < i + 1) {
          if (key.toString().substring(0, 2) == i) {
            obj.label = address.city_list[key]
            obj.value = address.city_list[key]
          }
          if (obj.label) {
            children.push(obj)
          }
        }
      }
      for (const key in address.province_list) {
        let obj = {}
        if (key.toString().substring(0, 2) < i + 1) {
          if (key.toString().substring(0, 2) == i) {
            obj.label = address.province_list[key]
            obj.value = address.province_list[key]
          }
          if (obj.label) {
            children2.push(obj)
          }
        }
      }
      if (children3.length > 0) {
        countyArr.push(children3)
      }
      if (children.length > 0) {
        cityArr.push(children)
      }
      if (children2.length > 0) {
        provinceArr = [...new Set(provinceArr), ...new Set(children2)]
      }
    }

    let countyChildren = [...new Set(countyArr)]
    let cityChildren = [...new Set(cityArr)]
    let provinceChildren = [...new Set(provinceArr)]
    let arrValue = []
    for (let c = 0; c < cityChildren.length; c++) {
      for (let q = 0; q < cityChildren[c].length; q++) {
        cityChildren[c][q].children = countyChildren[c][q]
      }
      provinceChildren[c].children = cityChildren[c]
      let obj = provinceChildren[c]
      arrValue.push(obj)
    }
    return arrValue
  },
  /***时间转换***/
  timeSwitch(val, judge) {
    let date = new Date(val)
    let Month = date.getMonth() + 1
    let day = date.getDate()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let second = date.getSeconds()
    if (judge) {
      return (
        date.getFullYear() +
        '-' +
        this.isTwo(Month) +
        '-' +
        this.isTwo(day) +
        ' ' +
        this.isTwo(hours) +
        ':' +
        this.isTwo(minutes) +
        ':' +
        this.isTwo(second)
      )
    } else {
      return (
        date.getFullYear() + '-' + this.isTwo(Month) + '-' + this.isTwo(day)
      )
    }
  },
  isTwo(val) {
    if (val.toString().length > 1) {
      return val
    } else {
      return '0' + val
    }
  },
  /**
   * 字符串转成Date对象*/
  strToDate(time, judge) {
    if (!time) {
      return '-'
    }

    if (time instanceof Date) {
      return this.timeSwitch(time, judge)
    }
    if (typeof time === 'number') {
      return this.timeSwitch(time, judge)
    }
    if (typeof time !== 'string') {
      return '-'
    }

    let date = new Date(time)
    if (isNaN(date.getDay())) {
      /**考虑是苹果浏览器不兼容的情况 */
      date = new Date(time.replace(/-/g, '/'))
      /**还是NaN 考虑是不合法的时间字符串 */
      if (isNaN(date.getDay())) {
        return '-'
      }
    }

    return this.timeSwitch(date, judge)
  },
  //只显示月份
  showMonth(val) {
    let date = new Date(val)
    let Month = date.getMonth() + 1
    return date.getFullYear() + '-' + this.isTwo(Month)
  },
  //根据出生日期计算年龄
  sumAge(value) {
    let currentYear = new Date().getFullYear()
    let currentMon = new Date().getMonth() + 1
    let currentDate = new Date().getDate()
    let birthYear = new Date(value).getFullYear()
    let birthMon = new Date(value).getMonth() + 1
    let birthDate = new Date(value).getDate()
    let differ = currentYear - birthYear
    if (birthMon > currentMon) {
      differ--
    } else if (birthMon == currentMon) {
      if (birthDate > currentDate) {
        differ--
      }
    }
    return differ
  },
  //根据身份证号查年龄
  sumAgeByIdCard(idCard) {
    var date = new Date()
    if (idCard) {
      var currentYear = date.getFullYear()
      var currentMon = date.getMonth() + 1
      var currentDate = date.getDate()
      var birthYear = idCard.substring(6, 10)
      var birthMon = idCard.substring(10, 12)
      var birthDate = idCard.substring(12, 14)
      var differ = currentYear - birthYear
      if (birthMon > currentMon) {
        differ--
      } else if (birthMon == currentMon) {
        if (birthDate > currentDate) {
          differ--
        }
      }
      return differ
    } else {
      return 0
    }
  },
  /***隐私化姓名***/
  privacyName(e) {
    if (e) {
      var length = e.length - 1
      var start = e.substring(0, 1)
      for (var i = 0; i < length; i++) {
        start = start + '*'
      }
      return start
    } else {
      return e
    }
  },
  /***隐私化手机号***/
  privacyPhone(e) {
    if (e) {
      var start = e.substring(0, 3)
      var end = e.substring(7, 11)
      return start + '****' + end
    } else {
      return e
    }
  },
  /***隐私化身份证***/
  privacyIdCard(e) {
    if (e) {
      var start = e.substring(0, 6)
      var end = e.substring(14, 18)
      return start + '********' + end
    }
  },
  genderFormatter(e) {
    if (e == 1) {
      return '男'
    } else {
      return '女'
    }
  },
  //乘法
  accMul(arg1, arg2) {
    var m = 0,
      s1 = arg1.toString(),
      s2 = arg2.toString()
    try {
      m += s1.split('.')[1].length
    } catch (e) {}
    try {
      m += s2.split('.')[1].length
    } catch (e) {}
    return (
      (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
      Math.pow(10, m)
    )
  },
  //加法
  accAdd(arg1, arg2) {
    var r1, r2, m
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2))
    return (arg1 * m + arg2 * m) / m
  },
  //除法
  accDiv(arg1, arg2) {
    var t1 = 0,
      t2 = 0,
      r1,
      r2
    try {
      t1 = arg1.toString().split('.')[1].length
    } catch (e) {}
    try {
      t2 = arg2.toString().split('.')[1].length
    } catch (e) {}
    r1 = Number(arg1.toString().replace('.', ''))
    r2 = Number(arg2.toString().replace('.', ''))
    return (r1 / r2) * Math.pow(10, t2 - t1)
  },
  //减法
  accSubtr(arg1, arg2) {
    var r1, r2, m, n
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2))
    //动态控制精度长度
    n = r1 >= r2 ? r1 : r2
    return ((arg1 * m - arg2 * m) / m).toFixed(n)
  },
}
export default base

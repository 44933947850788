import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import { tansParams } from '@/utils/ruoyi'
import { Message, Loading } from 'element-ui'
import Md5 from 'js-md5'
let loadinginstace
// 创建axios实例
const service = axios.create({
  // baseURL: process.env.VUE_APP_API_URL, // api的base_url
  baseURL: 'https://yluser.hzty-pension.cn/lifeServe', // api的base_url
  // baseURL: 'http://192.192.2.55:8085', // api的base_url
  timeout: 15000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json',
  },
})
service.interceptors.request.use(
  (config) => {
    if (sessionStorage.getItem('Authorization')) {
      config.headers['Authorization'] = sessionStorage.getItem('Authorization')
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params)
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }
    // const Base64 = require('js-base64').Base64

    const sortedEntries = Object.entries({
      // timestamp: new Date().getTime().toString(),
      ...config.data.data,
    })
      .filter(([key, value]) => value) // 过滤掉值为空的字段
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // 按键字母顺序排序
    let timestamp = new Date().getTime().toString()
    let sing =
      'timestamp' +
      '=' +
      timestamp +
      '&' +
      sortedEntries
        .map(([key, value]) => `${key}=${value}`) // 将键值对转换为 "key=value" 格式
        .join('&') // 用 '&' 连接
    console.log(sing, 'singMD5')

    // let data = {}
    // data = {
    //   des: 'Y',
    //   data: Base64.encode(
    //     JSON.stringify({
    //       ...config.data,
    //       sign: Md5(sing).toUpperCase(),
    //       timestamp: timestamp,
    //     })
    //   ),
     
    // }
    // console.log(data, 'canshu')
    // config.data = data
    console.log(config.data, '====>config')
    if (!config.noCodeTime) {
      // ...config.data
      // config.data = {
      //   ...config.data,
      //   code: 'accessToken',
      //   time: new Date().getTime()
      // }
    }
    // loadinginstace = Loading.service({
    //   lock: true,
    //   // text: 'Loading',
    //   spinner: "el-icon-loading",
    //   background: "rgba(250, 250, 250, 0.8)",
    //   customClass: "osloading",
    //   fullscreen: true,
    // });
    return config
  },
  (error) => {
    return error
  }
)
// respone拦截器
service.interceptors.response.use(
  (res) => {
    console.log(res, '===>相应拦截')
    // const Base64 = require('js-base64').Base64
    // let res = JSON.parse(Base64.decode(response.data.data))
    if (res.status === 200) {
      //加载loading动画,如果写在外一层 则登录的时候也会有动画
      // loadinginstace = Loading.service({
      //   lock: true,
      //   // text: 'Loading',
      //   spinner: "el-icon-loading",
      //   background: "rgba(250, 250, 250, 0.8)",
      //   customClass: "osloading",
      //   fullscreen: true,
      // });
      setTimeout(() => {
        if (loadinginstace) {
          loadinginstace.close()
        }
      }, 200)
      return Promise.resolve(res.data)
      // return Promise.resolve(JSON.parse(res.data))
    } else {
      setTimeout(() => {
        if (loadinginstace) {
          loadinginstace.close()
        }
      }, 200)
      Message.error('网络请求超时，请稍后再试')
      return Promise.reject(res)
    }
  },
  (err) => {
    console.log('网络异常')
    return Promise.reject(err)
  }
)

export function request(_param) {
  console.log(_param, '_param')
  const {
    method = 'get',
    // `responseType` 表示服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
    responseType = 'json', // 默认的
    headers = {},
    url = '',
    params,
    data,
    ...otherData
  } = _param

  if (!url) {
    return new Promise((resolve, reject) => {
      reject(new Error('url is null'))
    })
  }
  const _method = method.toLowerCase()
  if (_method === 'get') {
    return axios({
      responseType,
      url,
      baseURL: url,
      headers,
      method,
      params: params || data || otherData,
    })
  }

  if (_method === 'delete' || _method === 'put') {
    if (!Object.prototype.hasOwnProperty.call(headers, 'Content-Type')) {
      headers['Content-Type'] =
        'application/x-www-form-urlencoded;charset=UTF-8'
    }
    return axios({
      responseType,
      url,
      baseURL: process.env.VUE_APP_API_URL,
      headers,
      method,
      data,
      params: params || data || otherData,
    })
  }

  if (_method === 'post') {
    console.log(_method, '====>请求拦截')
    if (!Object.prototype.hasOwnProperty.call(headers, 'Content-Type')) {
      headers['Content-Type'] =
        'application/x-www-form-urlencoded;charset=UTF-8'
    }
    if (params && data) {
      return axios({
        responseType,
        url,
        headers,
        baseURL: url,
        method,
        params,
        data,
      })
    } else {
      const { start, limit, ...resetData } = otherData
      return axios({
        responseType,
        url,
        headers,
        baseURL: url,
        method,
        params: params || { start, limit },
        data: data || resetData,
      })
    }
  }
}
export default service

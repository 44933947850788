import base from '@/assets/js/base'

export default {
  valueFilter(e) {
    if ((base.isNumberVerify(e) && e >= 0) || e) {
      return e
    } else {
      return '-'
    }
  },
  dateFilter(e) {
    if (e) {
      return base.timeSwitch(e)
    } else {
      return '-'
    }
  },
  // 格式化数据，递归将空的children置为undefined
  formatCategoryData(data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].children.length < 1) {
        data[i].children = undefined
      } else {
        this.formatCategoryData(data[i].children)
      }
    }
    return data
  },
  formatGroupData(data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].childGroups.length < 1) {
        data[i].childGroups = undefined
      } else {
        this.formatGroupData(data[i].childGroups)
      }
    }
    return data
  }
}

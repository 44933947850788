<template>
  <div id="app">
    <el-container v-if="isLogin">
      <!--侧边栏-->
      <aside-bar></aside-bar>
      <!-- 内容框 -->
      <el-container>
        <!-- 头部 -->
        <el-header class="clearfix">
          <!--下拉菜单-->
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <!-- <el-avatar size="medium" icon="el-icon-user-solid"></el-avatar> -->
              <el-avatar size="medium" :src="url"></el-avatar>
              <span class="user-name">{{ username }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item command="/personal-center"
                >个人中心</el-dropdown-item
              > -->
              <el-dropdown-item divided command="/login"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <!--消息-->
          <!-- <el-badge :value="999" class="news" :max="99" :hidden="false">
            <i class="el-icon-bell" @click="$router.push('message-center')"></i>
          </el-badge> -->
        </el-header>
        <!-- 内容 -->
        <el-main>
          <div class="container">
            <router-view />
          </div>
          <!--底部-->
          <el-footer>Copyright © 2022 KangJiu</el-footer>
        </el-main>
      </el-container>
    </el-container>

    <router-view v-else />
  </div>
</template>
<script>
import asideBar from '@/components/aside-bar'
import icon from '@/assets/images/user.png'
export default {
  name: 'app',
  data() {
    return {
      isLogin: false,
      url: '',
      icon,
      username: 'admin管理员',
    }
  },
  components: {
    'aside-bar': asideBar,
  },
  computed: {
    // url() {
    //   return this.$store.state.user.avatar || this.icon
    // }
  },
  watch: {
    $route(to, from) {
      if (to.path == '/login' || to.path == '/404') {
        this.isLogin = false
      } else {
        this.isLogin = true
      }
      if (sessionStorage.getItem('login')) {
        this.username = JSON.parse(sessionStorage.getItem('login')).userName
      }
    },
    '$store.state.user.avatar': {
      handler(val) {
        this.url = val
      },
      immediate: true,
    },
  },
  created() {
    let offsetWidth = document.getElementsByTagName('body')[0].offsetWidth
    if (offsetWidth >= 1200) {
      this.isCollapse = false
    } else {
      this.isCollapse = true
    }
  },
  methods: {
    handleCommand(command) {
      if (command == '/login') {
        sessionStorage.clear()
        this.$router.push(command)
      } else {
        this.$router.push(command)
      }
    },
  },
}
</script>
<style scoped lang="scss">
#app,
.el-container {
  height: 100%;
}
.el-header {
  color: #333;
  background-color: #ffffff;
  border-bottom: 1px solid #ebeef5;
}
.news {
  float: right;
  margin-top: 20px;
  margin-right: 40px;
}
.el-icon-bell {
  color: #92929d;
  height: 24px;
  width: 24px;
  font-size: 24px;
  cursor: pointer;
}
.el-dropdown {
  float: right;
  width: 160px;
  height: 60px;
  cursor: pointer;
  height: 60px !important;
  line-height: 60px;
}
.el-dropdown-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-name {
  width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.el-footer {
  height: 50px !important;
  line-height: 50px;
  color: #808695;
  font-size: 14px;
  text-align: center;
  background-color: #f5f7f9;
}
.el-main {
  background-color: #f5f7f9;
  color: #333;
}
.container {
  min-height: calc(100vh - 140px);
}
.el-avatar--medium {
  width: 36px;
  height: 36px;
  ::v-deep img {
    width: 36px;
    height: 36px;
    object-fit: contain;
  }
}
</style>

const state = () => ({
  avatar: ''
})
const getters = {
  avatar: state => state.avatar
}
const mutations = {
  setAvatar(state, avatar) {
    state.avatar = avatar
  }
}
const actions = {}
export default { state, getters, mutations, actions }

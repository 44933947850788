<template>
  <el-aside style="width: auto">
    <transition name="el-zoom-in-center">
      <h2 v-show="!isCollapse">
        <!--  <img class="logo" src="@/assets/images/logo.png" /> -->
        后台管理系统
      </h2>
    </transition>
    <el-menu
      class="el-menu-vertical"
      :collapse="isCollapse"
      :default-active="this.$route.path"
      router
    >
      <fragment v-for="(item, index) in menu1" :key="item.menuRout">
        <!-- 有二级菜单 -->
        <template v-if="item.child.length > 0">
          <el-submenu :index="index.toString()" v-if="item.menuState == 101">
            <template slot="title">
              <i :class="item.menuIcon"></i>
              <span slot="title">{{ item.menuName }}</span>
            </template>
            <template v-for="(ite, inde) in item.child">
              <el-menu-item
                :key="ite.menuRout"
                :index="ite.menuRout"
                v-if="ite.menuState == 101"
                >{{ ite.menuName }}</el-menu-item
              >
            </template>
          </el-submenu>
        </template>
        <!-- 只有一级菜单 -->
        <template v-else>
          <el-menu-item :index="item.menuRout" v-if="item.menuState == 101">
            <i :class="item.menuIcon"></i>
            <span slot="title" v-if="item.menuName != '消息中心'">{{
              item.menuName
            }}</span>
            <span slot="title" v-else
              >{{ item.menuName }}({{ messageNumber }})</span
            >
          </el-menu-item>
        </template>
      </fragment>
    </el-menu>
  </el-aside>
</template>
<script>
import { translateTreeToData } from '@/utils'
import * as userApi from '@/api/user'
import { mapState, mapMutations } from 'vuex'
export default {
  data() {
    return {
      menu: [
        {
          menuIcon: 'el-icon-s-custom',
          menuName: '用户管理',
          menuRout: '/customer-management',
          child: [],
          menuState: 101,
        },
        {
          menuIcon: 'el-icon-s-custom',
          menuName: '商品管理',
          menuRout: '/shop',
          child: [],
          menuState: 101,
        },
      ],
      menu1: [],
      isCollapse: false,
      timer: null,
      searchForm: {
        menuName: '',
        pid: -1,
      },
    }
  },
  computed: {
    //消息
    ...mapState('upload', ['messageNumber']),
  },
  created() {
    this.getRoutes()
    this.massageNum()
    // this.timer = setInterval(() => {
    //   this.massageNum();
    // }, 30000);
    console.log('this.$store: ', this.$store.state)
    // this.menu1 = this.menu
  },
  destroyed() {
    clearInterval(this.timer)
  },
  watch: {
    $route(to, from) {
      if (to.path == '/message') {
        // this.massageNum();
      }
    },
  },
  methods: {
    ...mapMutations('upload', ['getMessage']),
    async massageNum() {
      // let { data } = await this.$base.postAjax(
      //   '/mallUser/AdminMessage/getAdminMessageNumber',
      //   {
      //     code: 'string',
      //     data: 'string',
      //     time: 'string',
      //   }
      // )
      // if (data.code == 200) {
      //   this.getMessage(data.data)
      // } else {
      //   this.$message.warning(data.msg)
      // }
      let params = {
        userAccount: JSON.parse(sessionStorage.getItem('login')).accountNumber,
        userId: JSON.parse(sessionStorage.getItem('login')).userId,
        // data: {
        //   ...this.searchForm,
        // },
        // page: this.currentPage,
        // pageSize: this.pageSize,
      }
      let res = await userApi.getInfo(params)
      if (res.code == 200) {
        this.menu1 = res.data.menus
      } else {
        this.$message.warning(res.msg)
      }
    },
    // async getRoutes() {
    //   const params = {
    //     data: {
    //       accountNumber: JSON.parse(sessionStorage.getItem("login"))
    //         .accountNumber,
    //       source: "PC",
    //     },
    //   };
    //   let { data } = await this.$base.postAjax(
    //     "/mallUser/Login/getUserMenu",
    //     params
    //   );
    //   if (data.code == 200) {
    //     this.$store.commit("user/setAvatar", data.data.headImage);
    //     const menulist = translateTreeToData(data.data.info).map(
    //       (item) => item.menuName
    //     );
    //   menulist.push("商户管理");
    //     menulist.push("用户管理");
    //     menulist.push("商户信息管理");
    //     menulist.push("店铺管理");
    //     menulist.push("结算管理");
    //     menulist.push("套餐管理");
    //     // console.log("menulist: ", menulist);
    //     this.menu1 = this.menu.map((item) => {
    //       if (!menulist.includes(item.menuName)) {
    //         item.hidden = true;
    //       } else {
    //         item.hidden = false;
    //         if (item.nextLevels && item.nextLevels.length > 0) {
    //           item.nextLevels.forEach((i) => {
    //             if (!menulist.includes(i.menuName)) {
    //               i.hidden = true;
    //             } else {
    //               i.hidden = false;
    //             }
    //           });
    //         }
    //       }
    //       return item;
    //     });
    //     // console.log("this.menu1: ", this.menu1);
    //   } else {
    //     this.$message.error(data.msg);
    //   }
    // },
    async getRoutes() {
      this.menu1 = [
        {
          menuIcon: 'el-icon-s-custom',
          menuName: '用户管理',
          menuRout: '/customer-management',
          child: [],
          menuState: 101,
        },

        {
          menuIcon: 'el-icon-s-custom',
          menuName: '菜单管理',
          menuRout: '/ment',
          child: [],
          menuState: 101,
        },
        {
          menuIcon: 'el-icon-s-custom',
          menuName: '角色管理',
          menuRout: '/Rolemanagement',
          child: [],
          menuState: 101,
        },
        {
          menuIcon: 'el-icon-s-custom',
          menuName: '商品管理',
          menuRout: '/shop',
          child: [],
          menuState: 101,
        },
        {
          menuIcon: 'el-icon-s-custom',
          menuName: '订单管理',
          menuRout: '/order',
          child: [],
          menuState: 101,
        },
        {
          menuIcon: 'el-icon-s-custom',
          menuName: '预约管理',
          menuRout: '/booking',
          child: [],
          menuState: 101,
        },
      ]
    },
    onResize() {
      let offsetWidth = document.body.getBoundingClientRect().width
      if (offsetWidth >= 1200) {
        this.isCollapse = false
      } else {
        this.isCollapse = true
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
  },
}
</script>
<style lang="scss" scoped>
.el-aside {
  position: relative;
  height: 100%;
  color: #333;
  background-color: #fafafa;
  box-shadow: 10px 0 10px -10px #c7c7c7;
}
.el-aside h2 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 220px;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  color: #37414b;
  font-weight: 700;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  border-bottom: 1px solid #ebeef5;
  .logo {
    height: 36px;
    width: auto;
    vertical-align: middle;
    margin-right: 4px;
  }
}
.el-menu {
  border-right: none;
}
.el-menu-vertical {
  position: relative;
  top: 60px;
}
.el-menu-vertical:not(.el-menu--collapse) {
  width: 220px;
  min-height: 400px;
}
.el-nextLevels .el-menu-item {
  background-color: #e8e8e8;
}
.el-nextLevels .el-menu-item:hover {
  background-color: #e1e1e1;
}
.el-nextLevels .el-menu-item.is-active:hover {
  background-color: #5482ee;
}
.el-menu-item:focus,
.el-menu-item.is-active {
  color: #fff;
  background-color: #5482ee;
}
@media screen and (max-width: 1200px) {
  .el-menu-vertical {
    top: 0;
  }
}
</style>

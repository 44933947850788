import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui'
Vue.use(VueRouter)
const errBack = () => {
  location.href = '/'
}
const routes = [
  {
    path: '/',
    redirect: '/customer-management',
  },
  {
    path: '/customer-management',

    component: () =>
      import('@/views/customer-management').catch((err) => errBack(err)),
  },
  {
    path: '/login',

    component: () =>
      import('@/views/login-register/login').catch((err) => errBack(err)),
  },
  {
    path: '/message',

    component: () => import('@/views/message').catch((err) => errBack(err)),
  },
  {
    path: '/shop',

    component: () => import('@/views/shop/index').catch((err) => errBack(err)),
  },
  {
    path: '/shopaddOredit',
    component: () =>
      import('@/views/shop/components/addshop').catch((err) => errBack(err)),
  },
  {
    path: '/ment',
    component: () => import('@/views/ment/index').catch((err) => errBack(err)),
  },
  {
    path: '/Rolemanagement',
    component: () =>
      import('@/views/Rolemanagement/index').catch((err) => errBack(err)),
  },
  {
    path: '/booking',

    component: () =>
      import('@/views/booking/index').catch((err) => errBack(err)),
  },
  {
    path: '/order',

    component: () => import('@/views/order/index').catch((err) => errBack(err)),
  },
  {
    path: '/orderForm',

    component: () =>
      import('@/views/order/orderForm').catch((err) => errBack(err)),
  },
  {
    path: '/bookingForm',

    component: () =>
      import('@/views/booking/bookingForm').catch((err) => errBack(err)),
  },
  // {
  //   path: "/workbench",

  //   component: () => import("@/views/workbench").catch((err) => errBack(err)),
  // },
  // {
  //   path: "/activity-management",

  //   component: () =>
  //     import("@/views/activity-management").catch((err) => errBack(err)),
  // },
  // {

  //   path: "/activity-management/add-activity",

  //   component: () =>
  //     import("@/views/activity-management/add-activity").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/activity-management/edit-activity",

  //   component: () =>
  //     import("@/views/activity-management/edit-activity").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/activity-management/view-product",

  //   component: () =>
  //     import("@/views/activity-management/view-product").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/order-management",
  //   component: () =>
  //     import("@/views/order-management").catch((err) => errBack(err)),
  // },
  // {
  //   path: "/customer-management",

  //   component: () =>
  //     import("@/views/customer-management").catch((err) => errBack(err)),
  // },
  // {
  //   path: "/customer-service-management",

  //   component: () =>
  //     import("@/views/customer-service-management").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/bill-management",

  //   component: () =>
  //     import("@/views/bill-management").catch((err) => errBack(err)),
  // },
  // {
  //   path: "/invoice-management",

  //   component: () =>
  //     import("@/views/invoice-management").catch((err) => errBack(err)),
  // },
  // //积分管理
  // {
  //   path: "/integral-management",

  //   component: () =>
  //     import("@/views/integral-management").catch((err) => errBack(err)),
  // },
  // //礼品卷
  // {
  //   path: "/coupons-management",
  //   component: () =>
  //     import("@/views/coupons-management").catch((err) => errBack(err)),
  // },
  // {
  //   path: "/coupons-management/add-coupons",
  //   component: () =>
  //     import("@/views/coupons-management/add-coupons").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/coupons-management/edit-coupons",
  //   component: () =>
  //     import("@/views/coupons-management/edit-coupons").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/coupons-management",
  //   component: () =>
  //     import("@/views/coupons-management").catch((err) => errBack(err)),
  // },
  // {
  //   path: "/order-management",

  //   component: () =>
  //     import("@/views/order-management").catch((err) => errBack(err)),
  // },
  // {
  //   path: "/order-group-management",

  //   component: () =>
  //     import("@/views/order-group-management").catch((err) => errBack(err)),
  // },
  // {
  //   path: "/distribution-management",

  //   component: () =>
  //     import("@/views/distribution-management").catch((err) => errBack(err)),
  // },
  // {
  //   path: "/distribution-person",

  //   component: () =>
  //     import("@/views/distribution-management/distribution-person").catch(
  //       (err) => errBack(err)
  //     ),
  // },
  // {
  //   path: "/cash-record",

  //   component: () =>
  //     import("@/views/distribution-management/cash-record").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/order-management/order-detail",

  //   component: () =>
  //     import("@/views/order-management/order-detail").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/order-group-management/order-detail",

  //   component: () =>
  //     import("@/views/order-group-management/order-detail").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/product-management/product-list",

  //   component: () =>
  //     import("@/views/product-management/product-list").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/product-management/add-product",

  //   component: () =>
  //     import("@/views/product-management/add-product").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/product-management/edit-product",

  //   component: () =>
  //     import("@/views/product-management/edit-product").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/product-management/group-product-list", //集采商品

  //   component: () =>
  //     import("@/views/product-management/group-product-list").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/product-management/edit-group-goods",

  //   component: () =>
  //     import("@/views/product-management/components/edit-group-goods").catch(
  //       (err) => errBack(err)
  //     ),
  // },
  // {
  //   path: "/product-management/grouping-management",

  //   component: () =>
  //     import("@/views/product-management/grouping-management").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/product-management/meal-management",

  //   component: () =>
  //     import("@/views/product-management/meal-management").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/product-management/store-management",

  //   component: () =>
  //     import("@/views/product-management/store-management").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/product-management/category-management",

  //   component: () =>
  //     import("@/views/product-management/category-management").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/system-management/account-management",

  //   component: () =>
  //     import("@/views/system-management/account-management").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/system-management/role-management",

  //   component: () =>
  //     import("@/views/system-management/role-management").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/system-management/operation-log",

  //   component: () =>
  //     import("@/views/system-management/operation-log").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/operations-management/information-management",

  //   component: () =>
  //     import("@/views/operations-management/information-management").catch(
  //       (err) => errBack(err)
  //     ),
  // },
  // {
  //   path: "/operations-management/organization-edit",

  //   component: () =>
  //     import("@/views/operations-management/components/organizationEdit").catch(
  //       (err) => errBack(err)
  //     ),
  // },
  // {
  //   path: "/operations-management/sojourn-edit",

  //   component: () =>
  //     import("@/views/operations-management/components/sojournEdit").catch(
  //       (err) => errBack(err)
  //     ),
  // },
  // {
  //   path: "/operations-management/by-management",
  //   component: () =>
  //     import("@/views/operations-management/by-management").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // //问候语
  // {
  //   path: "/operations-management/greeting",
  //   component: () =>
  //     import("@/views/operations-management/greeting").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/operations-management/today",
  //   component: () =>
  //     import("@/views/operations-management/today").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/operations-management/week",
  //   component: () =>
  //     import("@/views/operations-management/week").catch((err) =>
  //       errBack(err)
  //     ),
  // },
  // {
  //   path: "/message-center",

  //   component: () =>
  //     import("@/views/message-center").catch((err) => errBack(err)),
  // },
  // {
  //   path: "/personal-center",

  //   component: () =>
  //     import("@/views/personal-center").catch((err) => errBack(err)),
  // },
  // {
  //   path: "/bill-management",

  //   component: () =>
  //     import("@/views/bill-management").catch((err) => errBack(err)),
  // },
]

const router = new VueRouter({
  routes,
})
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  if (isChunkLoadFailed) {
    window.location.reload()
    // router.replace(router.history.pending.fullPath);
  } else {
    console.log(error)
  }
})

const routesWhiteList = ["/", "/login"];
// 导航守卫，非登录状态先登录
router.beforeEach(async (to, from, next) => {
  /* 路由发生变化修改页面title */
  let hasToken = sessionStorage.getItem("Authorization");
  if (hasToken) {
    if (to.path === "/login") {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    if (routesWhiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next({ path: "/login", replace: true });
      // next({ path: "/" });
    }
  }
});

// router.afterEach(to => {
//   document.title = getPageTitle(to.meta.title)
// })

export default router

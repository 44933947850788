import request from '@/utils/request'
const preFixUrl = '/push/imageOss'
// 上传
/**
 *
 * @param {*} data
 * @returns
 */
export const getUploadParams = (data) => {
  return request({
    url: `${preFixUrl}/Common/getUploadSign`,
    method: 'post',
    noCodeTime: true,
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}

/**
 *
 * @param {*} data
 * @returns
 */
export const getImageUrl = (data) => {
  return request({
    url: `${preFixUrl}/Common/getImageUrl`,
    method: 'post',
    noCodeTime: true,
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}

/**
 *天翼云上传图片
 * @param {*} data
 * @returns
 */
export const gettyUpload = (data) => {
  return request({
    url: `${preFixUrl}/tyUpload`,
    method: 'post',
    noCodeTime: true,
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}

/**
 *天翼云上传视频
 * @param {*} data
 * @returns
 */
export const gettyUploadVideo = (data) => {
  return request({
    url: `${preFixUrl}/Common/tyUploadVideo`,
    method: 'post',
    noCodeTime: true,
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}

/**
 *天翼云上传
 * @param {*} data
 * @returns
 */
export const getTYImageUrl = (data) => {
  return request({
    url: `${preFixUrl}/Common/getTYImageUrl`,
    method: 'post',
    noCodeTime: true,
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}

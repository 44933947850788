/**
 * @description 代码生成机状态管理
 */
import {
  getUploadParams,
  getImageUrl,
  gettyUpload,
  gettyUploadVideo,
  getTYImageUrl,
} from "@/api/upload";
import axios from "axios";
import { uuid } from "@/utils";
import base from "@/assets/js/base";
const state = () => ({
  ossParams: {
    key: "", // key后面有用，先默认设空字符串
    success_action_status: "200", // 默认200
    file: null,
  },
  messageNumber: 0,
});
const getters = {
  getOssParams: (state) => state.ossParams,
};

const mutations = {
  setOssParams(state, ossParams) {
    state.ossParams = ossParams;
  },
  getMessage(state, data) {
    state.messageNumber = data;
  },
};
const actions = {
  async setOssParams({ commit, state }, param) {
    const { file } = param;

    const params = {
      file,
    };
    commit("setOssParams", params);
  },
  async setUploadFile({ commit, state }, file) {
    var formData = new FormData();
    formData.append("photos", file);
    const { data } = await gettyUpload(formData);
    console.log(data);
    return {
      data: data[0].imageVa,
      key: data[0].imageKey,
    };
  },
};
export default { state, getters, mutations, actions };

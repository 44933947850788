import request from '@/utils/request'
const preFixUrl = '/user'
//process.env.NODE_ENV === 'development' ? "/wnnApi/processorUser" : '/fft/processorUser'

/**
 * 用户管理 -- 用户列表
 * @param {*} data
 * @returns
 */
export const userList = (data) => {
  return request({
    url: `${preFixUrl}/User/getUserAccount`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
export const mentList = (data) => {
  return request({
    url: `${preFixUrl}/menu/getAllMenus`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
export const deleteMenus = (data) => {
  return request({
    url: `${preFixUrl}/menu/deleteMenus`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
export const roleList = (data) => {
  return request({
    url: `${preFixUrl}/role/getRole`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
export const deleterole = (data) => {
  return request({
    url: `${preFixUrl}/role/deleteRole`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
export const getInfo = (data) => {
  return request({
    url: `${preFixUrl}/User/getUserMenuInfo`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
export const getRoleMenu = (data) => {
  return request({
    url: `${preFixUrl}/role/getRoleMenu`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}


export const addUserRoleBindin = (data) => {
  return request({
    url: `${preFixUrl}/role/addUserRoleBindin`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import promise from 'es6-promise'
import '@babel/polyfill'
import base from '@/assets/js/base'
import vueFragment from 'vue-fragment' //解决导航菜单循环数据不隐藏文字问题
import filters from '@/assets/js/filters'
import JsonExcel from 'vue-json-excel' //导出excel
import './assets/scss/index.scss'
import './assets/css/main.css'
import '@/assets/scss/ruoyi.scss' // ruoyi css
import store from './store'
import VueClipboard from 'vue-clipboard2'
import VueCropper from 'vue-cropper'
import { handleTree } from '@/utils/ruoyi'
Vue.use(VueCropper)
//main.js
import moment from 'moment'
Vue.prototype.moment = moment
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.component('downloadExcel', JsonExcel)
promise.polyfill()

//全局引入工具库
Vue.prototype.$base = base
Vue.prototype.$filters = filters
//全局过滤器
Object.keys(filters).forEach((v) => {
  // 注册
  Vue.filter(v, filters[v])
})
Vue.prototype.handleTree = handleTree
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(vueFragment.Plugin)

new Vue({
  router,
  store,
  render: function (h) {
    return h(App)
  },
}).$mount('#app')
if (process.env.NODE_ENV !== 'development') {
  console.log = function () {}
  alert = function () {}
}
